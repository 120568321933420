.deviceCard {
  cursor: pointer;
  height: 2.5rem;
  min-height: 2.5rem;
  /* background-color: #1e2530; */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  gap: 1.25rem;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  text-align: right;
}

.deviceCard:hover {
  background-color: rgb(56, 67, 84);
}

.searching {
  height: 2.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  background-color: #1e2530;
  padding-left: 0.6rem;
}

.selected {
  background-color: #313843;
}

.deviceHeader {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
}

.deviceImgWrapper {
  position: relative;
  margin-right: 9px;
  width: 30px;
}

.checkBoxWrapper {}

.deviceStatus {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.6rem;
  /* color: red; */
  font-size: 0.85rem;
  white-space: nowrap;
  font-weight: 500;
}

.deviceInfo {
  display: flex;
  flex-direction: row;
}

.deviceName {
  color: #a7a8ab;
  font-size: 0.9rem;
  white-space: nowrap;
}

.offline .deviceName, .searching .deviceName {
  color: #5c5c5c;
}

.online .deviceStatus {
  color: #15ee9c;
}

.offline .deviceStatus, .searching .deviceStatus {
  color: #5c5c5c;
}

.active .deviceStatus {
  color: #ff0000;
}

.absoluteDotWrapper {
  position: absolute;
  bottom: -1px;
  right: -2px;
}

.dot {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: inline-block;
}

.online .dot {
  background-color: #15ee9c;
}

.offline .dot {
  background-color: #5c5c5c;
}

.searching .dot {
  background-color: #5c5c5c;
  animation: blinker 2s ease infinite;
}

.searching .dot:nth-of-type(1) {
  animation-delay: 200ms;
}

.searching .dot:nth-of-type(2) {
  animation-delay: 400ms;
}

.searching .dot:nth-of-type(3) {
  animation-delay: 600ms;
}

.active .dot {
  background-color: red;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}